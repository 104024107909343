import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  NavLink,
  Paragraph,
  Text,
} from "theme-ui";
import { DynamicImage } from "../components/elements/Image";
import { List } from "../components/elements/List";
import { Layout } from "../components/layout/layout";
import { Section } from "../components/layout/section";
import { CallToAction } from "../components/sections/calltoaction";
import { Testimonials } from "../components/sections/testimonials";
import { Remark } from "react-remark";
import * as React from "react";
import { IDynamicImageType } from "../types";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { FeedbackShowcase } from "../components/sections/FeedbackShowcase";

interface IRegion {
  pageContext: {
    region: {
      columnTwoTitle: ReactNode;
      columnTwoSubtitle: ReactNode;
      columnOneSubtitle: any;
      columnOneTitle: ReactNode;
      meta: object;
      regionThumbnail: object;
      // Arrays
      feedbackTypes: object[];
      servicesJobs: {
        length: number;
        map(
          arg0: (job: any) => import("theme-ui/jsx-runtime").JSX.Element
        ): React.ReactNode;
        title: string;
      };
      // Images
      heroDataLogo: IDynamicImageType;
      heroImage: IDynamicImageType;
      additionalInformationImage: IDynamicImageType;
      programPerksImage: IDynamicImageType;
      // Headlines
      heroAboveHeadline: string;
      heroHeadline: string;
      cardPosition: string;
      informationHeadline: string;
      additionalInformationAboveHeadline: string;
      additionalInformationHeadline: string;
      // Subtext
      informationSubtext: string;
      additionalInformationSubtext: string;
      heroSubtext: string;
      // Links
      heroButtonLink: string;
      additionalInformationLink: string;
      // Misc
      heroDataRegionHighlights: string;
      // Thumbnail & Seo
      regionName: string;
      regionMetadata: {
        description: string;
        title: string;
        twitterCard: object;
        image: object;
      };
      purpleBoxHeadline: string;
      purpleBoxSubtext: string;
      programPerksList: string;
      programPerksHeadline: string;
      programPerksSubtext: string;
      featuredProgramAboveHeadline: string;
      featuredProgramHeadline: string;
      featuredProgramBelowHeadline: string;
      featuredProgramSubtext: string;
      featuredProgramButtonLink: string;
      featuredProgramCallToAction: string;
      featuredProgramImage: IDynamicImageType;
      regionTestimonialShowcase: {
        testimonialName: string;
        testimonialProgram: string;
        testimonialImage: IDynamicImageType;
        testimonialContent: string;
        testimonialCaption: string;
        bullets: string;
      };
      jobCentersAboveHeadline: string;
      jobCentersHeadline: string;
      jobCentersFullServiceLocations: {
        length: number;
        map(
          arg0: (location: any) => import("theme-ui/jsx-runtime").JSX.Element
        ): React.ReactNode;
        name: string;
        address: string;
      };
      jobCentersSatelliteLocations: {
        map(
          arg0: (location: any) => import("theme-ui/jsx-runtime").JSX.Element
        ): React.ReactNode;
        address: string;
        name: string;
      };
      position: any;
      id: string;
    };
  };
}

export default ({ pageContext }: IRegion) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const { region } = pageContext;
  const { feedbackTypes, servicesJobs } = region;
  const seo = {
    title: region.regionMetadata?.title || region.heroHeadline,
    description: region.regionMetadata?.description || region.heroSubtext,
    image: region.regionMetadata?.image || region.regionThumbnail,
    meta: region.meta,
    article: true,
  };
  const isLargerThan551 = useMediaQuery("(min-width: 551px)");

  const cardPosition = React.useMemo(() => {
    switch (region.cardPosition) {
      case "bottom-left":
        return { left: "10%", top: 220 };
      case "center-left":
        return { left: "-5%", top: 5 };
      default:
        break;
    }
  }, [region.cardPosition]);

  return (
    <Layout type='region' seo={seo}>
      <Section sx={{ backgroundColor: "text", paddingTop: 0 }}>
        <Grid columns={[1, 2]}>
          <Flex
            sx={{
              margin: 0,
              padding: 0,
              position: "relative",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              bottom: -100,
            }}
          >
            <Card
              sx={{
                position: "absolute",
                left: [0, cardPosition?.left],
                top: [null, cardPosition?.top],
                zIndex: 10,
              }}
            >
              <DynamicImage
                sx={{
                  maxWidth: 260,
                  objectFit: "contain",
                  display: "block",
                }}
                src={region.heroDataLogo}
                alt={""}
              />

              <Remark
                rehypeReactOptions={{
                  components: {
                    ul: (props: any) => {
                      return (
                        <Box
                          as='ul'
                          sx={{ listStyleType: "none", paddingLeft: 0 }}
                          {...props}
                        />
                      );
                    },
                    strong: (props: any) => {
                      return (
                        <Heading
                          variant='h3'
                          as='h3'
                          sx={{ color: "secondary", fontFamily: "heading" }}
                          {...props}
                        />
                      );
                    },
                    li: (props: any) => (
                      <Flex
                        as='li'
                        sx={{
                          gap: 2,
                          alignItems: "flex-end",
                          color: "#74727d",
                          fontFamily: "heading",
                          fontSize: [1, 3],
                          flexWrap: "wrap",
                        }}
                        {...props}
                      />
                    ),
                  },
                }}
              >
                {region.heroDataRegionHighlights}
              </Remark>
            </Card>
            <DynamicImage src={region.heroImage} alt={region.regionName} />
          </Flex>
          <Flex
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              padding: 5,
            }}
          >
            <Heading
              variant='h3'
              as='span'
              sx={{
                color: "#aac457",
                fontFamily: "heading",
                textAlign: ["center", "left"],
              }}
            >
              {region.heroAboveHeadline}
            </Heading>
            <Heading
              variant='h1'
              as='h1'
              sx={{ color: "#fff", textAlign: ["center", "left"] }}
            >
              {region.heroHeadline}
            </Heading>
            <Paragraph
              sx={{
                color: "muted",
                textAlign: ["center", "left"],
                opacity: 0.8,
              }}
            >
              {region.heroSubtext}
            </Paragraph>
            <Flex sx={{ justifyContent: ["center", "flex-start"] }}>
              <Button
                as={NavLink}
                variant='link'
                href={region.heroButtonLink}
                target='_blank'
                sx={{ maxWidth: 200, fontWeight: "bold" }}
              >
                Get Started
              </Button>
            </Flex>
          </Flex>
        </Grid>
      </Section>
      <FeedbackShowcase
        title={region.informationHeadline}
        description={region.informationSubtext}
        feedback={feedbackTypes}
      />
      <Section sx={{ backgroundColor: "#f8f6ff" }}>
        <Grid columns={[1, 2]} sx={{ alignItems: "end" }}>
          <Box>
            <Heading
              as='h2'
              variant='h2'
              sx={{ color: "#8d73fe", fontFamily: "heading" }}
            >
              {region.additionalInformationAboveHeadline}
            </Heading>
            <Heading as='h2' variant='h2'>
              {region.additionalInformationHeadline}
            </Heading>
            <Paragraph>{region.additionalInformationSubtext}</Paragraph>
            <List
              sx={{
                color: "text",
              }}
            >
              <ul>
                {servicesJobs?.map((job) => (
                  <li>{job.title}</li>
                ))}
                <li>{servicesJobs?.length >= 1 && "And More!"}</li>
              </ul>
            </List>
            <Button
              as={NavLink}
              variant='link'
              href={region?.additionalInformationLink}
              sx={{ marginTop: 3 }}
              target='_blank'
            >
              Get Started
            </Button>
          </Box>
          <DynamicImage
            src={region?.additionalInformationImage}
            alt={region?.additionalInformationImage?.alt}
          />
        </Grid>
      </Section>
      {region.purpleBoxHeadline && (
        <Section
          dark
          title={region.purpleBoxHeadline}
          description={region.purpleBoxSubtext}
          sx={{
            backgroundColor: "secondary",
            color: "#fff !important",
            p: {
              fontWeight: "bold !important",
              margin: "8px auto",
              fontSize: 3,
            },
          }}
        />
      )}

      {region.programPerksHeadline && (
        <Section>
          <Grid columns={[1, 2]}>
            <DynamicImage src={region?.programPerksImage} />
            <Box>
              <Heading
                variant='h2'
                sx={{
                  maxWidth: 800,
                }}
              >
                {region.programPerksHeadline}
              </Heading>

              <Paragraph
                sx={{
                  maxWidth: 700,
                  margin: "28px auto",
                }}
              >
                {region.programPerksSubtext}
              </Paragraph>
              <List
                sx={{ color: "text" }}
                markdown={region.programPerksList}
                // fullwidth={!isLargerThan551}
              />
            </Box>
          </Grid>
        </Section>
      )}
      {region.featuredProgramHeadline && (
        <Section sx={{ backgroundColor: "#f8f6ff" }}>
          <Grid columns={[1, 2]}>
            <Box>
              <Heading
                variant='h5'
                as='h3'
                sx={{ color: "secondary", fontSize: 3, marginBottom: 0 }}
              >
                {region.featuredProgramAboveHeadline}
              </Heading>
              <Heading
                variant='h2'
                sx={{
                  maxWidth: 800,
                  marginTop: 0,
                }}
              >
                {region.featuredProgramHeadline}
              </Heading>
              <Text
                as='p'
                sx={{
                  fontWeight: "bold",
                  color: "rgb(0 0 0 / 60%)",
                  marginTop: 2,
                }}
              >
                {region.featuredProgramBelowHeadline}
              </Text>
              <Paragraph
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  color: "rgb(0 0 0 / 60%)",
                }}
              >
                {region.featuredProgramSubtext}
              </Paragraph>
              <Paragraph
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  color: "rgb(0 0 0 / 60%)",
                }}
              >
                {region.featuredProgramCallToAction}
              </Paragraph>
              <Button
                as={NavLink}
                variant='link'
                href={region.featuredProgramButtonLink}
                target={"_blank"}
              >
                Get Started
              </Button>
            </Box>
            <DynamicImage src={region?.featuredProgramImage} alt={""} />
          </Grid>
        </Section>
      )}
      <Testimonials data={region.regionTestimonialShowcase} />
      <Section sx={{ backgroundColor: "#f8f6ff" }}>
        <Heading
          variant='h5'
          as='h4'
          sx={{
            color: "secondary",
            fontSize: 3,
            margin: "0",
            textAlign: "center",
          }}
        >
          {region.jobCentersAboveHeadline}
        </Heading>
        <Heading
          variant='h2'
          as='h2'
          sx={{
            maxWidth: 800,
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          {region.jobCentersHeadline}
        </Heading>
        <Grid columns={[1, 2]} sx={{ marginTop: 6, gap: 0 }}>
          <Box
            sx={{
              paddingLeft: [0, 5],
              paddingRight: [0, 5],
              borderRight: ["none", "1px solid #979797"],
            }}
          >
            <Box>
              {region.columnOneTitle && (
                <Heading
                  variant='h5'
                  as='span'
                  sx={{
                    color: "secondary",
                    fontSize: 3,
                    marginBottom: 3,
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  {region.columnOneTitle}
                </Heading>
              )}
              {region.columnOneSubtitle && (
                <Text as='i' sx={{ display: "inline-block", marginLeft: 1 }}>
                  {region.columnOneSubtitle}
                </Text>
              )}
            </Box>
            <Grid
              columns={[
                1,
                region.jobCentersFullServiceLocations?.length > 2 ? 2 : 1,
              ]}
            >
              {region.jobCentersFullServiceLocations?.map((location) => (
                <Box>
                  <Heading as='h4' variant='span' sx={{ fontFamily: "body" }}>
                    {location.name}
                  </Heading>
                  <Paragraph
                    sx={{ color: "#262626", marginTop: 1, marginBottom: 1 }}
                  >
                    {location.address}
                  </Paragraph>
                  <Paragraph
                    sx={{ color: "#262626", marginTop: 1, marginBottom: 1 }}
                  >
                    Call:{location.phoneNumber}
                  </Paragraph>
                  {location.hours && (
                    <Paragraph
                      sx={{ color: "#262626", marginTop: 1, marginBottom: 1 }}
                    >
                      Hours:{location.hours}
                    </Paragraph>
                  )}
                </Box>
              ))}
            </Grid>
          </Box>
          <Box sx={{ paddingLeft: [0, 5], paddingRight: [0, 5] }}>
            <Box>
              {region.columnTwoTitle && (
                <Heading
                  variant='h5'
                  as='span'
                  sx={{
                    color: "secondary",
                    fontSize: 3,
                    marginBottom: 3,
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  {region.columnTwoTitle}
                </Heading>
              )}
              {region.columnTwoSubtitle && (
                <Text as='i' sx={{ display: "inline-block", marginLeft: 1 }}>
                  {region.columnTwoSubtitle}
                </Text>
              )}
            </Box>
            <Grid columns={[1, 2]}>
              {region.jobCentersSatelliteLocations?.map((location) => (
                <Box>
                  <Heading as='h4' variant='span' sx={{ fontFamily: "body" }}>
                    {location.name}
                  </Heading>
                  <Paragraph
                    sx={{ color: "#262626", marginTop: 1, marginBottom: 1 }}
                  >
                    {location.address}
                  </Paragraph>
                  <Paragraph
                    sx={{ color: "#262626", marginTop: 1, marginBottom: 1 }}
                  >
                    Call: {location.phoneNumber}
                  </Paragraph>
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Section>
      <CallToAction />
    </Layout>
  );
};
